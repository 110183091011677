.message-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.4rem;
  color: #000;
  font-size: 0.3733rem;
  line-height: 0.5333rem;
}

.message-content-container .message-title {
  text-align: center;
  margin-top: 0.4rem;
  font-size: 0.4rem;
  font-weight: 700;
}

.message-content-container .message-content {
  width: 100%;
  text-align: left;
  margin-top: 0.4rem;
}

.message-content-container .fun-image {
  margin-top: 0.4rem;
}