.file-upload-container .file-list {
  display: flex;
}

.file-upload-container .file-list .file-list-item {
  position: relative;
}

.file-upload-container .file-list .file-list-item-del {
  position: absolute;
  top: 0.0533rem;
  right: 0.0533rem;
  display: flex;
  justify-content: center;
  width: 0.2rem;
  height: 0.2rem;
  line-height: normal;
  border-radius: 100%;
  color: #000;
  z-index: 1;
}

.file-upload-container .file-list-upload-default {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.0133rem solid #949aa7;
  border-radius: 0.2133rem;
}

.file-upload-container .file-list-upload {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-upload-container .file-list-upload input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}