.application-guidelines-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.36rem 0.6667rem 1.3333rem 0.6667rem;
  background: #dbdbdb;
  color: #000;
  font-size: 0.4rem;
  line-height: 0.7333rem;
}

.application-guidelines-container .application-guidelines-title {
  font-size: 0.52rem;
  font-weight: bolder;
  line-height: 0.64rem;
  display: flex;
  justify-content: center;
}

.application-guidelines-container .application-guidelines-sub-title {
  font-size: 0.5067rem;
  font-weight: bold;
  padding-left: 0.2667rem;
  position: relative;
  margin-top: 0.4933rem;
}

.application-guidelines-container .application-guidelines-sub-title::before {
  content: "";
  width: 0.16rem;
  height: 0.4667rem;
  position: absolute;
  top: 0.08rem;
  bottom: 0;
  left: 0;
  background: #cbb486;
  border-radius: 1.0133rem;
}

.application-guidelines-container .application-guidelines-step-content {
  margin-top: 0.2533rem;
  font-family: HarmonyOS Sans SC;
}

.application-guidelines-container .application-guidelines-step-one-img-container {
  display: flex;
  justify-content: space-around;
  margin-top: 0.4933rem;
  margin-bottom: 0.2667rem;
}

.application-guidelines-container .application-guidelines-step-one-img-container .application-guidelines-step-one-img {
  width: 3.08rem;
  height: 3.84rem;
}

.application-guidelines-container .application-guidelines-step-two-img,
.application-guidelines-container .application-guidelines-step-four-img {
  width: 8.56rem;
  height: 17.6667rem;
  margin-top: 0.2533rem;
}

.application-guidelines-container .application-guidelines-step-two1-img {
  width: 8.56rem;
  height: 4.9733rem;
  margin-top: 0.36rem;
}

.application-guidelines-container .application-guidelines-step-three-img {
  width: 8.56rem;
  height: 7.68rem;
  margin-top: 0.2267rem;
}

.application-guidelines-container .application-guidelines-step-five-img {
  width: 8.56rem;
  height: 13.4933rem;
  margin-top: 0.4933rem;
}

.application-guidelines-container .application-guidelines-step-six-img {
  width: 8.56rem;
  height: 13.6267rem;
  margin-top: 0.2533rem;
}

.application-guidelines-container .application-guidelines-step-seven-attention-container {
  display: flex;
  flex-direction: column;
  padding: 1.0267rem 0;
}

.application-guidelines-container .application-guidelines-step-seven-attention {
  font-style: 0.3467rem;
  line-height: 0.4267rem;
  text-align: justify;
  margin-top: 0.4rem;
}