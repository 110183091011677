.upload-material-container {
  display: flex;
  flex-direction: column;
  padding: 0.5333rem 0.6667rem;
  background-color: #fff;
}

.upload-material-container .title,
.upload-material-container .sub-title,
.upload-material-container .main-title {
  font-size: 0.4533rem;
  color: #000;
  font-weight: bold;
  font-family: "HarmonyOS Sans SC";
}

.upload-material-container .case-check_upload_reason-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #1c9145;
  padding: 0.1333rem 0.2rem;
  margin-bottom: 0.2667rem;
}

.upload-material-container .case-check_upload_reason {
  font-size: 0.3733rem;
  color: #000;
  font-weight: bold;
}

.upload-material-container .main-title {
  text-align: center;
}

.upload-material-container .column-style,
.upload-material-container .uploaded-material,
.upload-material-container .case-material,
.upload-material-container .case-name-container {
  display: flex;
  flex-direction: column;
}

.upload-material-container .case-name-container {
  margin-top: 0.2667rem;
}

.upload-material-container .case-name-container .content {
  font-size: 0.3733rem;
  color: #000;
  margin-top: 0.08rem;
  line-height: 0.5333rem;
}

.upload-material-container .case-material {
  margin-top: 0.4rem;
}

.upload-material-container .case-material .content {
  font-size: 0.3467rem;
  color: #000;
  margin-top: 0.1333rem;
  line-height: 0.5333rem;
}

.upload-material-container .button-width {
  width: 8rem;
}

.upload-material-container .button-container {
  margin-top: 0.8rem;
}

.upload-material-container .button-container .button-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.upload-material-container .uploaded-material {
  margin-top: 0.5333rem;
}

.upload-material-container .uploaded-material .material-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.1333rem 0.2rem;
  border-radius: 0.0616rem;
  background: #fff;
  border: 0.0308rem solid #d9d9d9;
  margin-top: 0.5333rem;
}

.upload-material-container .uploaded-material .material-item .material-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.upload-material-container .uploaded-material .material-item .material-name-container .material-name {
  flex: 1;
  color: #000;
  font-size: 0.3467rem;
  margin-left: 0.1333rem;
  margin-top: 0.1333rem;
}