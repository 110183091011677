.appointment-detail-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.appointment-detail-container .appointment-detail-top-cotainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.appointment-detail-container .appointment-detail-top-cotainer .appointment-detail-top-bg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 5.5333rem;
  background: #cbb486;
  border-radius: 0 0 1.0267rem 1.0267rem;
}

.appointment-detail-container .appointment-detail-top-cotainer .appointment-detail-top-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  margin-top: 0.6133rem;
}

.appointment-detail-container .appointment-detail-top-cotainer .appointment-detail-top-content-container .appointment-detail-status-img {
  width: 1.4133rem;
  height: 1.4667rem;
}

.appointment-detail-container .appointment-detail-top-cotainer .appointment-detail-top-content-container .appointment-detail-work-order-status {
  color: #fff;
  font-size: 0.4533rem;
  font-weight: bolder;
  margin-top: 0.6667rem;
}

.appointment-detail-container .appointment-detail-top-cotainer .appointment-detail-top-content-container .appointment-detail-attention {
  margin: 0.6667rem 0.6667rem 0 0.6667rem;
  background: #fff;
  box-shadow: 0 0.2rem 0.4rem rgba(51,39,18,.1);
  border-radius: 0.4rem;
  line-height: 0.6667rem;
  color: #000;
  font-size: 0.3467rem;
  padding: 0.4rem 0.6133rem;
}

.appointment-detail-container .appointment-detail-sub-title {
  color: #000;
  font-size: 0.5067rem;
  font-weight: bold;
  padding-left: 0.2667rem;
  position: relative;
  margin-top: 0.64rem;
}

.appointment-detail-container .appointment-detail-sub-title::before {
  content: "";
  width: 0.16rem;
  height: 0.4667rem;
  position: absolute;
  top: 0.08rem;
  bottom: 0;
  left: 0;
  background: #cbb486;
  border-radius: 1.0133rem;
}

.appointment-detail-container .appointment-detail-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 0.8rem 0.8rem 0.8rem;
}

.appointment-detail-container .appointment-detail-content-container .appointment-detail-basic-info-item-container {
  margin-top: 0.4rem;
}

.appointment-detail-container .appointment-detail-content-container .appointment-detail-basic-info-item-container .appointment-detail-basic-info-item {
  display: flex;
  flex-direction: row;
}

.appointment-detail-container .appointment-detail-content-container .appointment-detail-basic-info-item-container .appointment-detail-basic-info-item .appointment-detail-basic-info-item-left {
  width: 2.1333rem;
  color: #383838;
  font-size: 0.3467rem;
  font-weight: 400;
  line-height: 0.6667rem;
  text-align: end;
}

.appointment-detail-container .appointment-detail-content-container .appointment-detail-basic-info-item-container .appointment-detail-basic-info-item .appointment-detail-basic-info-item-right {
  flex: 1;
  color: #000;
  font-size: 0.3467rem;
  margin-left: 0.16rem;
  font-weight: bold;
  line-height: 0.6667rem;
  text-align: left;
}

.appointment-detail-container .appointment-detail-content-container .appointment-detail-question-detail {
  color: #000;
  font-size: 0.3733rem;
  font-weight: 400;
  margin: 0.4667rem 0 0.8rem 0;
}

.appointment-detail-container .appointment-detail-btn {
  margin-top: 0.5333rem;
}