.new-appointment-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.new-appointment-container .new-appointment-content-container {
  margin: 0.5333rem;
}

.new-appointment-container .new-appointment-content-container .new-appointment-sub-title {
  color: #000;
  font-size: 0.5067rem;
  font-weight: bold;
  padding-left: 0.2667rem;
  position: relative;
}

.new-appointment-container .new-appointment-content-container .new-appointment-sub-title::before {
  content: "";
  width: 0.16rem;
  height: 0.4667rem;
  position: absolute;
  top: 0.08rem;
  bottom: 0;
  left: 0;
  background: #cbb486;
  border-radius: 1.0133rem;
}

.new-appointment-container .new-appointment-content-container .new-appointment-question-detail {
  margin-top: 0.6133rem;
}

.new-appointment-container .new-appointment-content-container .new-appointment-item-container,
.new-appointment-container .new-appointment-content-container .new-appointment-btn {
  margin-top: 0.5333rem;
}

.new-appointment-container .new-appointment-content-container .new-appointment-item1 {
  margin-top: 0.5333rem;
  align-items: flex-start;
  padding: 0.2667rem;
  text-align: start;
  vertical-align: top;
}

.new-appointment-success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-appointment-success-container .new-appointment-success-img {
  width: 3.5333rem;
  height: 2.64rem;
}

.new-appointment-success-container .new-appointment-success-text {
  color: #000;
  font-size: 0.48rem;
  font-weight: bold;
  margin-top: 0.5333rem;
}

.new-appointment-success-container .new-appointment-success-notice {
  color: #000;
  font-size: 0.4rem;
  font-weight: 400;
  white-space: pre-wrap;
}

.new-appointment-success-container .new-appointment-success-notice-margin {
  margin-top: 0.2667rem;
}

.new-appointment-attention-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0.4667rem;
}

.new-appointment-attention-container .new-appointment-attention-title {
  color: #000;
  font-size: 0.4533rem;
  font-weight: bold;
}

.new-appointment-attention-container .new-appointment-attention {
  color: #000;
  font-size: 0.3733rem;
  text-align: center;
  line-height: 0.5333rem;
  text-indent: 0.8rem;
  margin: 0.2rem 0.8rem 0 0.8rem;
  text-align: justify;
}

.new-appointment-attention-container .new-appointment-attention-app-btn {
  margin: 0.4rem 1.6rem 0 1.6rem;
  background-color: #277ae6;
}

.new-appointment-attention-container .new-appointment-attention-btn {
  margin: 0.4rem 1.6rem 0 1.6rem;
  background-color: #cbb486;
}

.new-appointment-attention-container .fun-button {
  width: auto;
  display: flex;
  justify-content: center;
}

.new-appointment-attention-container .new-appointment-attention-line {
  border: 0.0133rem solid #b0b0b0;
  margin: 0.4rem 0.4667rem;
}

.fun-dialog .fun-dialog-main .fun-dialog-action .fun-dialog-confirm {
  color: #fff;
  background: #cbb486;
}